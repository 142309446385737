.AboutMeContainer {
    display: grid;
    grid-template: 'leftHandSide rightHandSide' 1fr/ 1fr 1fr;
    grid-gap: 10px;
    height: 100vh;
    width: 98vw;
    background-color: black;
}

.leftHandSide {
    grid-area: leftHandSide;
    padding: 50px;
    display: grid;
    grid-template-areas: 'textContent';
    grid-template-columns: 1fr;
}

.content h1 {
    color: orange;
}

.aboutMeTop {
    color: pink;
    font-size: x-large;
    font-weight: bold;
}

.aboutMe {
    color: orange;
}

.leftHandSide .content {
    line-height: 1.5;
    grid-area: textContent;
    text-align: justify;
}

.leftHandSide p {
    font-size: 25px;
}

.rightHandSide {
    grid-area: rightHandSide;
    color: white;
    /* border: 1px dashed white; */
    display: grid;
    padding: 2rem;
    grid-template-rows: 600px 50px 50px;
    grid-gap: 5px;
}

.rightHandSide .socialMediaLink {
    display: grid;
    grid-template-columns: 50px 50px;
    color: white;
    grid-gap: 10px;
    justify-content: center;
}

.rightHandSide .cv {
    font-size: 30px;
    font-weight: bold;
    color: orange;
    justify-self: center;
}

.rightHandSide .picture {
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    margin-top: 6rem;
}

.rightHandSide a {
    text-decoration: none;
}

@media screen and (max-width: 1000px) {
    .AboutMeContainer {
        display: grid;
        grid-template: 'leftHandSide' 1fr 'rightHandSide' 1fr / 1fr;
        grid-gap: 10px;
        height: 100vh;
        width: 98vw;
    }
}

@media screen and (max-width: 750px) {
    .leftHandSide p {
        font-size: 18px;
    }
}

@media screen and (max-width: 497px) {
    .rightHandSide .picture .resize {
        max-width: 100%;
        max-height: auto;
        object-fit: cover;
    }
}