.text {
    border-radius: 5px;
    padding: 5rem;
    background-color: black;
    grid-area: text;
    margin: 2rem;
}

.container {
    display: grid;
    grid-template: 'text' 1fr / 1fr;
    height: 100vh;
    width: 100vw;
    background-color: black;
    margin-top: -100px;
}

.text .name-title {
    color: orange;
    font-size: 6rem;
}

.text .role-title {
    font-size: 5rem;
}

.text .location-name {
    font-size: 3rem;
}

.text .current-skills {
    font-size: 2rem;
}

h2 {
    color: orangered;
    font-weight: 5rem;
}

h3 {
    color: orange;
}

p {
    color: orange;
}

@media screen and (max-width: 541px) {
    .text .name-title {
        color: orange;
        font-size: 3rem;
    }
    .text .role-title {
        font-size: 2rem;
    }
    .text .location-name {
        font-size: 2rem;
    }
    .text .current-skills {
        font-size: 1rem;
    }
}