* {
    padding: none;
    margin: none;
    box-sizing: border-box;
}

html {
    font-family: 'Open Sans', sans-serif;
}

body {
    background-color: black;
}

.slide-in-left-enter {
    transform: translateX(-100%);
}

.slide-in-left-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 200ms;
}

.slide-in-left-exit {
    transform: translateX(0%);
    opacity: 1;
}

.slide-in-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 200ms;
}